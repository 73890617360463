import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout"
import {getTranslatedFields, getCurrentLocale, getFallbackLocale, getTranslatedValue} from "../helpers/fields";

const {getRecordSlug, getMapSlug} = require("../helpers/slugs");

class Room extends React.Component {

    handleToggle = () => {
        document.querySelector('.toggle-me').classList.toggle('active');
    };

    handleKeyUp = () => {
        document.querySelector('.toggle-me').addEventListener('keyup', (event) => {
            if (event.keyCode === 13 || event.keyCode === 32) {
                this.handleToggle();
            }
        });
    };

    getRecordsWithHighlighted = (records, highlightedId) => {
        if (highlightedId === undefined || highlightedId === null) {
            return records;
        }

        const highlightedArray = records.filter((record) => {
           return record.id === "/api/contents/" + highlightedId;
        });

        if (highlightedArray.length === 0 ) {
            return records;
        }

        const highlighted = highlightedArray[0];
        const highlightedIndex = records.indexOf(highlighted);

        records.splice(highlightedIndex, 1);
        records.unshift(highlighted);

        return records;
    };

    render() {
        const translatedFields = getTranslatedFields(this);

        const {title, name, description, highlightedrecord, audio } = translatedFields;
        let parsedDescription = '';

        if(typeof DOMParser !== 'undefined') {
            const parser = new DOMParser();
            parsedDescription = description !== undefined
                ? parser.parseFromString(description, 'text/html').body.textContent
                : parsedDescription;
        }

        const roomFromTitle = title !== undefined && title.includes(':') ? title.split(':')[0] : title;
        const titleWithoutRoom = title !== undefined && title.includes(':') ? title.split(':')[1] : title;

        const subtitle = this.props.pageContext.theme !== undefined
            ? getTranslatedValue(this, this.props.pageContext.theme.fieldValues.title)
            : false;

        const relatedRecords = this.getRecordsWithHighlighted(this.props.pageContext.relatedRecords, highlightedrecord);
        const locale = getCurrentLocale(this);

        const translations = this.props.pageContext.translations;
        const includeMissingLocaleWarning = this.props.pageContext.data.fieldValues.title[getCurrentLocale(this)] === undefined;

        return (
            <Layout lang={locale} className='kunstwerken themadetail' title={roomFromTitle} page={this} translations={translations} includeMissingLocaleWarning={includeMissingLocaleWarning}>
                <h3>{titleWithoutRoom}</h3>
                {subtitle &&
                    <>
                        <h4>{subtitle}</h4>
                        <br />
                    </>
                }
                <p className="meta">{name} - <Link to={getMapSlug(getCurrentLocale(this))}>Toon op kaart</Link></p>

                {parsedDescription &&
                <div className="toggle-me" onClick={this.handleToggle} onKeyUp={this.handleKeyUp}>
                    <p dangerouslySetInnerHTML={{__html: parsedDescription}}/>
                    <a href="#" className="read-more">{getTranslatedValue(this, translations.show_more)}</a>
                    <a href="#" className="read-less">{getTranslatedValue(this, translations.show_less)}</a>
                </div>
                }

                {audio && audio.url &&
                <audio controls>
                    <source src={audio.url} type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio>
                }

                <div className="wrapper">
                    {relatedRecords.map((record, index) => {
                        const url = record.fieldValues.image[getCurrentLocale(this)] !== undefined && record.fieldValues.image[getCurrentLocale(this)].url ? record.fieldValues.image[getCurrentLocale(this)].url
                            : record.fieldValues.image[getFallbackLocale(this)] !== undefined && record.fieldValues.image[getFallbackLocale(this)].url ? record.fieldValues.image[getFallbackLocale(this)].url
                                : record.fieldValues.image.nl_NL.url;

                        const alt = record.fieldValues.image[getCurrentLocale(this)] !== undefined && record.fieldValues.image[getCurrentLocale(this)].url ? record.fieldValues.image[getCurrentLocale(this)].alt
                            : record.fieldValues.image[getFallbackLocale(this)] !== undefined && record.fieldValues.image[getFallbackLocale(this)].url ? record.fieldValues.image[getFallbackLocale(this)].alt
                                : record.fieldValues.image.nl_NL.alt;
                        return (
                            <Link to={getRecordSlug(record, locale)} className="aspect-ratio-square" key={index}>
                                <img src={url} alt={alt}/>
                            </Link>
                        )
                    })}
                </div>
            </Layout>
        );
    }
}

export default Room;
